import ins from "./request"
// 带有参数的应该如何写
// 自己写的
/* export async function getBlogs(page, limit, categoryid,keyword) {
    return await ins.get("/api/blog", {
        params: {
            page: page || 1,
            limit: limit || 10,
            categoryid:categoryid||-1,
            keyword: keyword || "",
        },
    });
} */

// 老师写的
// 不同：老师直接将参数的默认值写在了后面
// 老师没有写keyword
export  async function getBlogs(page = 1, limit = 10, categoryId = -1, keyword = "") {
    return await ins.get("/api/blog", {
        params: {
            page,
            limit,
            categoryId,
            keyword,
        }
    });
}


/**
 * 获取博客分类 _myself
 */
/* export default async function getBlogTypes() {
    return await ins.get("/api/blogtype", "get");
} */


/* 
获取博客分类-teacher
*/
export async function getBlogCategories(){
    return await ins.get("/api/blogtype/");
}


/**
 * 获取单个博客_myself
 */
/* export default async function getOneBlog(id) {
    return await ins.get("/api/blog/" + id, "get")
} */
/* getOneBlog().then(resp =>{
    console.log(resp.data);
}) */

/* 
获取单个博客
注：不是很会写后面带有id 的
*/
export async function getBlog(id){
    return await ins.get(`/api/blog/${id}`)
}



// 提交评论请求规格
/* 
path: /api/comment
method: POST
body: {
	nickname: "昵称",
	content: "评论内容，纯文本",
	blogId: <id>	#评论的博客id
}
*/

/*
提交评论 -myself
*/
/* export async function sumbitComment(nickname,content,blogId){
    return await ins.post("/api/comment",{
        body:{
            nickname,
            content,
            blogId,
        }
    })
}
*/

/**
 * 提交评论
 * @param {*} id 
 * 注意：请求体不会写，请求体作为参数传递进去
 */
export async function postComment(commentInfo){
    // 请求地址，请求体
    return await ins.post("/api/comment",commentInfo)
}

/*
分页获取评论 -myself
*/
/* export async function getComments(page = 1, limit = 10, blogid = -1, keyword = ""){
    return await ins.get(page, limit, blogid,keyword)
    } 
*/


/**
 * 分页获取评论
 * @param {*} page 
 * @param {*} limit 
 * @param {*} blogid 
 * @param {*} keyword 
 * @returns 
 * params 配置指的是？后面的东西
 */
export async function getComments(blogId = -1,page = 1, limit = 10){
   
    return await ins.get("/api/comment",{
        params:{
            page,
            limit,
            blogId,
        }
    })
} 






