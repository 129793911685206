<template>
  <div class="app-container">
    <Layout class="test-container">
      <template #left>
        <div class="aside">
          <SiteAside />
        </div>
      </template>
      <template #default>
        <!-- <div>中间主区域</div> -->
        <!-- 这个组件是全局注册的，不用自己再次注册 -->
        <!-- 该组件可以写成短横线命名 -->
        <!-- <router-view> </router-view> -->
        <RouterView />
      </template>
    </Layout>
    <ToTop/>
  </div>
</template>

<script>
// 左边栏
import SiteAside from "@/components/SiteAside";
// 布局组件
import Layout from "@/components/Layout";
// 回到顶部组件
import ToTop from "@/components/ToTop";
export default {
  components: {
    SiteAside,
    Layout,
    ToTop,
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/global.less";
@import "~@/styles/var.less";
@import "~@/styles/mixin.less";
// 撑满
.app-container {
  // background-color: red;
  // 撑满容器
  .self-fill(fixed);
  .aside {
    width: 250px;
    height: 100%;
  }
}
</style>
