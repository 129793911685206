/* 
// 导出指令配置对象
export default {
    // el 使用指令的那个dom 元素
    // binding 是一个对象有属性
    bind(el, binding) {
        // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
        // 根据binding.value的值，决定创建或删除img元素
        console.log(el)
        console.log(binding)
    },
    update(el, binding) {        }
        */
// 导入静态资源
import imageUrl from "@/assets/loading.svg"
// 导入样式
import styles from "./loading.module.less";
/**
 * 判断el中是否存在loading效果的img元素
 * @param {*} el 
 */
function getLoadingImage(el) {
    // 通过自定义属性进行查找，判断是否已存在loading 
    return el.querySelector("img[data-role=loading]");
}
// 创建loading元素
function creteLoadingImg(el) {
    const img = document.createElement("img");
    // 设置自定义属性
    img.dataset.role = "loading";
    img.src = imageUrl;
    // img.classList.add(styles.loading);
    img.className = styles.loading;
    return img;
}
// 指令简化 如果这两个钩子函数实现的功能相同，可以直接把指令配置简化为一个单独的函数
export default function (el, binding) {
    // 该函数会被同时设置到bind和update中
    // 拿到当前的curImg 不一定有
    const curImg = getLoadingImage(el);
    // 根据binding.value的值，决定创建或删除img元素
    if (binding.value) {
        // 可能 binding 、和update 各创建一次，需要判断是否已经有了
        // 设置自定义属性，方便查找，
        // 需要判断有没有img元素
        if (!curImg) {
            // 没有loading 的图片，且当前处于正在加载的状态
            const img = creteLoadingImg();
            el.appendChild(img);
        }
    }
    else {
        // 加载完成后删除子元素
        if (curImg) {
            curImg.remove();
        }
    }
}