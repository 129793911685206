// 对拦截器进行封装
import axios from 'axios';
// 导入showMessage
import { showMessage } from '@/utils';
// 创建axios 实例，实例里面有配置
// 可以用实例发出请求
const ins = axios.create();
// 添加响应拦截器,函数作为参数
// 凡是通过实例得到的响应，都会下运行use里面的函数,然后再返回数据
ins.interceptors.response.use(function (resp) {
    // code为零时想要的数据，code不为0 说明有错误（api 接口的规定）
    if (resp.data.code != 0) {
        showMessage(
            {
                content: resp.data.msg,
                type: 'error',
                duration: 1500,
            }
        )
        return null;
    }
    // 没有错误，返回正常的数据
    return resp.data.data;
})

// 导出实例
export default ins;