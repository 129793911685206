<!-- 跟着老师写的 -->
<template>
  <ul class="contact-container">
    <!-- github -->
    <li>
      <a target="_blank" :href="data.github">
        <div class="icon">
          <Icon type="github" />
        </div>
        <span>{{ data.githubName }}</span>
      </a>
    </li>
    <!-- mail -->
    <li>
      <a :href="'mailto:'+data.mail">
        <div class="icon">
          <Icon type="mail" />
        </div>
        <span>{{ data.mail}}</span>
      </a>
    </li>
    <!-- qq -->
    <li>
      <a
        :href="'tencent://message/?Menu=yes&uin='+data.qq+'&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a595b1714f9d45'"
      >
        <div class="icon">
          <Icon type="qq" />
        </div>
        <span>{{data.qq}}</span>
      </a>
      <!-- 弹出层 -->
      <div class="pop">
        <!-- <img
          src="https://img2.baidu.com/it/u=1989038259,1842360401&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500"
        /> -->
        <img
          :src="data.qqQrCode"
        />
      </div>
    </li>
    <!-- weixin -->
    <li>
      <a href="">
        <div class="icon weixin">
          <Icon type="weixin" />
        </div>
        <span>{{data.weixin}}</span>
      </a>
      <!-- 弹出层 -->
      <div class="pop">
        <!-- <img
          src="https://img2.baidu.com/it/u=1989038259,1842360401&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500"
        /> -->
        <img
          :src="data.weixinQrCode"
        />
      </div>
    </li>
  </ul>
</template>

<script>
// 最好使用@符号，便于后期维护
import Icon from "@/components/Icon";
import {mapState} from "vuex"
export default {
  components: {
    Icon,
  },
  computed:{
    ...mapState("setting",["data"])
  }
};
</script>

<style lang="less" scoped>
@import "~@/styles/mixin.less";
@import "~@/styles/var.less";
.contact-container {
  list-style: none;
  padding: 20px 0;
  margin: 0;
  @itemHeight: 30px;
  //   每个li 的设置
  li {
    height: @itemHeight;
    line-height: @itemHeight;
    margin: 14px 0;
    color: @gray;
    position: relative;
    // 设置一个变量
    &:hover {
      .pop {
        transform: scaleY(1);
        transition: 1s;
      }
    }
    .weixin {
      font-size: 32px;
      text-indent: -3px;
    }
  }

  a {
    display: flex;
    // 纵向居中
    align-items: center;
    cursor: pointer;
    font-size:14px;
  }
  .icon {
    font-size: 26px;
    width: 36px;
  }
  .pop {
    position: absolute;
    left: 0;
    bottom: @itemHeight+5px;
    padding: 10px 15px;
    background: #fff;
    border-radius: 5px;
    // 一开始不显示,下面的中间为原点放大
    transform: scaleY(0);
    transform-origin: center bottom;
    img {
      width: 140px;
      height: 140px;
    }
    // 小三角用伪元素
    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      left: 50%;
      // 横向居中
      transform: translateX(-50%) rotate(45deg);
      bottom: -4px;
      background-color: #fff;
    }
  }
}
</style>
ce
