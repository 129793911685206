// 函数防抖
// 用途 传递一个新的函数进来，返回一个新的函数
//参数： 防抖的函数，时间 默认是100毫秒
export default function (fn, duration=100) {
    let timer = null;
    // 传递参数进去
    return (...args) => {
        // 清除之前的计时器
        clearTimeout(timer);
        // 设置计时器
        timer = setTimeout(() => {
            fn(...args)
        }, duration);
    }
}