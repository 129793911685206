<template>
  <!-- Layout 组件 -->
  <div class="layout-container">
    <!-- 左边栏 -->
    <div class="left">
      <slot name="left"></slot>
    </div>
    <!-- 中间主区域 -->
    <main class="main">
      <!-- 我们希望把主要内容放这里，提供插槽，名为default -->
      <slot name="default"></slot>
    </main>
    <!-- 右边栏 -->
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.layout-container {
  width: 100%;
  height: 100%;
  display: flex;
  //    left right 不可以增长不可以压缩宽度
  .left,
  .right {
    flex: 0 0 auto;
    overflow: hidden;
  }
  .main {
    // 可以增长，可以压缩
    flex: 1 1 auto;
    overflow: hidden;
  }
}
</style>
