import getComponentRootDom from "./getComponentRootDom";
import Icon from "@/components/Icon";
import styles from "./showMessage.module.less";

/**
 * 弹出消息
 * @param {string} content  消息内容
 * @param {string} type 消息的类型 info（普通消息） warn error success
 * @param {Number}  duration 持续的时间,单位ms,多久后消失
 * @param {HTMLElement} container 容器 消息会显示到容器的正中，如果不穿显示到页面的正中
 */
// 跟着老师直接写的 = 后面是默认值
// content, type = "warn", duration = 2000, container 改造为一个对象，方便使用
// 默认为空对象
export default function (options = {}) {
    // ||后面是默认值
    const content = options.content || "";
    const type = options.type || "info";
    const duration = options.duration || 2000;
    const container = options.container || document.body;

    const div = document.createElement("div");
    // 得到组件,通过函数调用
    const iconDom = getComponentRootDom(Icon, {
        type,
    });
    // 不同的类型，颜色不同
    const typeClassName = styles[`message-${type}`];
    console.log(`message-${type}`)
    // console.log(iconDom);
    div.innerHTML = `<span class = ${styles.icon} >${iconDom.outerHTML}</span> <div>${content}</div> `;
    // className 是自带的 
    div.className = `${styles.message} ${typeClassName}`;


    // 逻辑，先判断有没有容器，没有容器直接加入到body 中去
    // 如果有容器，看容器的position 是否改动过，是不是statci 
    // 因为这个消息要绝对定位在container，所以对container有一定的要求


    // 看容器的position 是否为静态
    // 不要设置body为relative
    if (options.container) {
        if (getComputedStyle(container).position == "static") {
            container.style.position = 'relative';
        }
    }


    // 将div 加入到父容器中
    container.appendChild(div);
    // 浏览器强行渲染一次，读取宽高等都可以导致渲染
    div.clientHeight;
    // 回到正常位置
    div.style.opacity = 1;
    div.style.transform = `translate(-50%,-50%)`;
    //  向上移动，等一段时间消失
    setTimeout(() => {
        div.style.transform = `translate(-50%,-50%) translateY(-20px)`;
        div.style.opacity = 0;
        div.style.transition = duration;
        // 添加监听事件，动画结束之后移出元素,事件监听一次
        div.addEventListener("transitionend", function () {
            // 移出div
            div.remove();
            // 消失的时候如果有回函数，运行回调函数
            options.callback && options.callback();
        }, { once: true });
    }, duration);

}
