// 将时间戳转换为指定的格式
// 参数：时间戳
// 参数 showTime 控制是否显示时分秒
export default function (timeStamp,showTime=false) {
    // 转换为数字 得到一个日期
    const date = new Date(+timeStamp);
    // 保证month是两位数,先转换为字符串，不足两位开始时补充0
    const month = (date.getMonth() + 1).toString().padStart(2, 0);
    // 保证天数为两位
    // 老师没有写关于天数的
    const day = date.getDate().toString().padStart(2, 0);
    // 如果需要显示 时分秒
    let str = `${date.getFullYear()}-${month}-${day}`;
    // 如果需要显示 时分秒
    if(showTime){
        const hour = date.getHours().toString().padStart(2,0);
        const minute = date.getMinutes().toString().padStart(2,0);
        const second = date.getSeconds().toString().padStart(2,0);
        str+= ` ${hour}:${minute}:${second}`;
    }
    return str;
}