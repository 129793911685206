// import request from "./request";

// export async function getSetting() {
//   return await request.get("/api/setting");
// }
import ins from "./request";

export async function getSetting() {
  return await ins.get("/api/setting/");
}
