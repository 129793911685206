
// import Home from '@/views/Home';
// import About from '@/views/About';
// import Blog from '@/views/Blog';
// import Project from '@/views/Project';
// import Message from '@/views/Message';
// import BlogDetail from '@/views/Blog/Detail'
import NotFound from "@/views/NotFound"

// 进度条
// css 导入
import "nprogress/nprogress.css"
// js 具名导入
import {start,done,configure} from "nprogress"
configure({
  // 进度条速度
  trickleSpeed:20,
  // 不显示转的小圈圈
  showSpinner:false,
})

// 延迟函数
function delay(duration){
  return new Promise(resovle=>{
    setTimeout(()=>{
      resovle()
    },duration)
  })
}
// 传入函数，调用的时候加载组件
// 如果直接传入 import(...),会直接调用，先就计算出表达式，加载所有组件
function getPagecomponent(pagecomponentResolver){
  return async ()=>{
    start()
    // 如果是开发阶段，手动等待一段时间，模拟
    // process 是node环境中的，下面的代码在浏览器环境中执行
    // vue-cli 向浏览器中注入了一些代码
    // 因此可以在这里使用
    if(process.env.NODE_ENV === "development"){
      await delay(1000)
    }
    const comp = await pagecomponentResolver()
    done()
    return comp
  }
}


// 写配置
// 路由配置 数组每一项是一个规则，一个规则是一个对象
//   导出一个数组
export default [
  {
    name: "Home",
    path: "/",
    // import promise
    // 传入一个函数
    component:getPagecomponent(()=>import("@/views/Home")),
    meta: {
      title: "首页"
    }
  },
  {
    name: "About",
    path: "/about",
    component: getPagecomponent(()=>import(/* webpackChunkName: "about" */ "@/views/About")),
    meta: {
      title: "关于我"
    }
  },
  {
    name: "Blog",
    path: "/article",
    component: getPagecomponent(()=>import(/* webpackChunkName: "blog" */ "@/views/Blog")),
    meta: {
      title: "文章"
    }
  },
  // 动态路由 获取不同分类下的文章  可以匹配的地址/blog/cate/1  1可以是2 3 是动态变化的
  {
    name: "CategoryBlog",
    path: "/article/cate/:categoryId",
    component:getPagecomponent(()=> import(/* webpackChunkName: "categoryBlog" */ "@/views/Blog")),
    meta: {
      title: "文章"
    }
  },
  // 文章详情页
  {
    name: "BlogDetail",
    path: "/article/:id",
    component: getPagecomponent(()=>import(/* webpackChunkName: "blogDetail" */ "@/views/Blog/Detail")),
    meta: {
      title: "文章详情"
    }
  },
  {
    name: "Project",
    path: "/project",
    component: getPagecomponent(()=>import(/* webpackChunkName: "project" */ "@/views/Project")),
    meta: {
      title: "项目&效果"
    }
  },
  {
    name: "Message",
    path: "/message",
    component: getPagecomponent(()=>import(/* webpackChunkName: "message" */ "@/views/Message")),
    meta: {
      title: "留言板"
    }
  },
  // 前面的都没有匹配，一定被它匹配
  {
    name:"NotFound",
    path:"*",
    component:NotFound,
    meta:{
      title:"not found"
    }
  }
]