import { getBanners } from "@/api/banner"
import {server_URL} from "@/urlConfig.js"
export default {
    namespaced: true,
    state: {
        loading: true,
        data: [],
    },
    mutations: {
        setLoading(state, payLoad) {
            state.loading = payLoad;
        },
        setData(state, payLoad) {   
            
            state.data = payLoad;
        }
    },
    actions: {
        async fetchBanner(ctx) {
            // 如果有数据，则不再重新请求
            // 第一次点击首页拿数据，切换路由再切换回来
            // 则不需要拿数据
            if(ctx.state.data.length>0){
                return
            }
            ctx.commit("setLoading", true);
            const resp = await getBanners()
            console.log(resp)
            // 后端处理过来的没有加服务器地址
            for(let item of resp){
                item.bigImg = server_URL + item.bigImg 
                item.midImg = server_URL + item.midImg 
            // 小图
            }
            ctx.commit("setData", resp)
            ctx.commit("setLoading", false)
        }
    },
}