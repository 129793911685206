import { getAbout } from "@/api/about"
export default {
    namespaced: true,
    state: {
        loading: true,
        data: "",
    },
    mutations: {
        setLoading(state, payLoad) {
            state.loading = payLoad;
        },
        setData(state, payLoad) {
            state.data = payLoad;
        }
    },
    actions: {
        async fetchAbout(ctx) {
            // 如果有数据，则不再重新请求
            // 第一次点击首页拿数据，切换路由再切换回来
            // 则不需要拿数据
            if(ctx.state.data){
                return
            }
            ctx.commit("setLoading", true);
            const resp = await getAbout()
            ctx.commit("setData", resp)
            ctx.commit("setLoading", false)
            console.log(resp)
        }
    },
}