// 入口文件
import Vue from 'vue';
import App from './App.vue';
// 导入全局样式
import "./styles/global.less";
import router from "./router";
import store from "./store"
// 导入模拟数据
// import "./mock";

import vLoading from "@/directives/loading";
import vLazy from "@/directives/lazy"
//  配置全局指令
// 指令的名称和配置对象
Vue.directive("loading", vLoading)
Vue.directive("lazy", vLazy);

import showMessage from "./utils/showMessage";
Vue.prototype.$showMessage = showMessage;
// 全局设置各个组件都可能触发
store.dispatch("setting/fetchSetting")
new Vue({
  router,// 路由匹配规则
  store,
  render: h => h(App),
}).$mount('#app')




// css moudle 知识点

// import styles from "./styles/message.module.less";
// console.log(styles) // 打印出来是空对象
// console.log("!!!!")
// const div = document.createElement("div")
// document.body.appendChild(div);
// div.className = styles.message;
// div.innerText = "创建了一个div";


// 返回真实结点的案例

// function getComponentRootDom(comp, props){
//   const vm = new Vue({
//     render: h => h(comp, {props})
//   })
//   // 挂载之后才可以生成真是的dom 元素
//   vm.$mount();
//   // el 表示渲染出来的真实的dom 元素
//   return vm.$el;
// }
// import Icon from "./components/Icon";
// var dom = getComponentRootDom(Icon, {type:"home"});
// console.log(dom);

// 向实例中注入成员
// Vue.prototype.$sayHello = function () {
//   console.log("hello!!!!")
// }
// var vm = new Vue({
//   router,// 路由匹配规则
//   render: h => h(App),
// }).$mount('#app')
// vm.sayHello();

// 弹出消息测试
// import showMessage from "./utils/showMessage";
// window.showMessage = showMessage;
// showMessage("123","info",2000);


// 对blog的接口进行测试
/* 
  import * as blogApi from "./api/blog";
  // 博客分类测试

  blogApi.getBlogTypes().then((r) => {
    console.log("博客分类", r);
  })

  // 博客所有文章
  // 将参数直接传入
  // page = 1, limit = 10, categoryid = -1, keyword = ""
  blogApi.getBlogs(2, 5, 3, "").then((r) => {
    console.log(r)
  })

 */
// 将blog.js 的接口全部导入
import * as blogApi from "@/api/blog";
// 测试 提交评论接口
/* const commentInfo ={
  nickname: "小太阳",
	content: "评论内容，纯文本，",
  // 评论的博客id
	blogId: 1	
}
blogApi.postComment(commentInfo).then((r) => {
  console.log(r)
}) */

// 测试分页获取评论
/* blogApi.getComments(1,8,-1,"").then((r) => {
  console.log(r)
}) */






// 测试 事件总线
/* 
import eventBus from "./eventBus";
function handler1(data){
  console.log("handler1",data)
};
function handler2(data){
  console.log("handler2",data)
}

eventBus.$on("event1",handler1);
eventBus.$on("event1",handler2);
eventBus.$on("event2",handler1);

window.eventBus = eventBus;
window.handler1 = handler1;
window.handler2=handler2;  

*/


// 测试全局设置

import "./eventBus";





