import { getSetting } from "@/api/setting"
import { titleController } from "@/utils"
import {server_URL} from "@/urlConfig.js"
export default {
    namespaced: true,
    state: {
        loading: false,
        data: null,
    },
    mutations: {
        setLoading(state, payLoad) {
            state.loading = payLoad
        },
        setData(state, payLoad) {
            state.data = payLoad
        }
    },
    actions: {
        async fetchSetting(ctx) {
            ctx.commit("setLoading", true)
            const resp = await getSetting()

            resp.avatar = server_URL + resp.avatar
            
            ctx.commit("setData", resp)
            ctx.commit("setLoading", false)

            // 添加网站小图标
            /* <link rel="shortcut icon " type="images/x-icon" href="./favicon.ico">
            <link-  v rel="shortcut icon " type="images/x-icon" href="http://www.jd.com/favicon.ico"> */
            if (resp.favicon) {

                // 之前的图标清除
                // let beforeLink1 = document.querySelector("link[rel='icon']");
                // if(beforeLink1){
                //     beforeLink1.remove()
                // }

                // 判断是否有这个
                let link = document.querySelector("link[rel='icon']");
                // 更改为自己的图标
                if (link) {
                    link.rel = "shortcut icon"
                    link.type = "images/x-icon"
                    link.href = resp.favicon
                  
                    document.querySelector("head").appendChild(link)
                }
                else {
                    link = document.createElement("link")
                    link.rel = "shortcut icon"
                    link.type = "images/x-icon"
                    link.href = resp.favicon
                  
                    document.querySelector("head").appendChild(link)
                }
            }

            // 设置网站标题
            // 个人空间
            if (resp.siteTitle) {
                titleController.setSiteTitle(resp.siteTitle)
            }
        }
    }
}