<template>
  <div ref="toTopContainer" @click = handleClick v-show = "show" class="to-top-container">
    top
  </div>

</template>

<script>
export default {
  data(){
    return {
      show:false,
    };
},
created(){
// 添加事件总线接口
// 事件名称 事件处理函数
this.$bus.$on("mainScroll",this.handleScroll);
},
destroyed() {
  // this.show = false;
  // 第二个参数不传递值，dom 元素没有，滚动高度没有，切换页面ToTop组件消失
  this.$bus.$off("mainScroll",this.handleScroll);
},
methods:{
  // 事件总线触发的时候，会传入dom 元素进来
  handleScroll(dom){
    /* 
    切换页面，destroyed 触发事件总线上的事件，
    dom元素消失，没有dom 元素，ToTop 消失
    destroyed 触发事件时,没有传递dom 元素
    */
    if(!dom){
      this.show = false;
      return;
    }
    // 当触发什么条件时，top 出现，滚动到500及以上出现top
    this.show = dom.scrollTop >=500;
  },
  // 点击后需要回到顶部
  /* 
  注意：回到顶部时，需要知道回到哪个组件的顶部，
  组件是`ToTop` ,有可能回到文章详情的顶部，文章列表的顶部等 ；
  解决方案：事件总线
  */
 handleClick(){
  // 设置滚动条的高度，参数：滚动条的高度
  this.$bus.$emit("setMainScroll",0);
 },
}
}
</script>

<style scoped lang = "less">
// 导入 
@import "~@/styles/var.less";
.to-top-container{
    background: @primary;
    width:50px;
    height:50px;
    border-radius:50%;
    color:@white;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    // 相对于视口定位
    position: fixed;
    right: 50px;
    bottom: 50px;
    // 设置z-index 重叠样式表
    // z-index 较大的元素会覆盖较小的元素在上层进行显示
    z-index:99;
}
</style>