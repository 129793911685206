/**
 * 调用该函数，可以设置合适的图片（哪些图片需要加载）
 */
function handleScroll() {
  setImages();
}

// 找到合适的图片
function setImages() {
  // 循环图片，对每一张图片进行处理
  for (const img of imgs) {
      // 处理单张图片
      setImage(img);
  }
}

// 处理单张图片
// 每一样图片是否在合适的范围
function setImage(img) {
  // 不管是否是在视口范围内，先使用这一张，浏览器会对图片进行缓存
  img.dom.src = defaultGIF;
  // 判断图片是否在视口呢
  // 获取视口高度
  const clientHeight = document.documentElement.clientHeight;
  // 获取当前元素的位置信息
  const rect = img.dom.getBoundingClientRect();
  // 给定一个最小高度，因为top 可能没有值
  const height = rect.height || 100;
  // 在视口范围内，top <=视口高度并且 top >=负的元素的高度（元素漏出来一点，也要加载）
  if (rect.top <= clientHeight && rect.top >= -height) {
      //  在视口范围内
      // 等加载完成后替换,等真实图片加载完成
      /*     
      const tempImg = new Image();
          tempImg.onload = function(){
              // 真是dom 元素的src 地址，是图片对象保留的地址
              img.dom.src = img.src;
          }
          tempImg.src = img.src;
          console.log("加载图片真是地址",img.dom) 
          */

      // 元素的src 地址是 img元素里面保存的地址{dom:el,src:bindings.value}
      img.dom.src = img.src;
      // 将加载过的图片从数组中移出,数组中保留没有加载过的
      // 这样同一页上下翻动加载过的不用重新加载   
      imgs =  imgs.filter((i) => i !== img);
  }
}

// 图片懒加载
// 导入静态资源
import defaultGIF from "@/assets/default.gif"
// 导入事件总线
import eventBus from "@/eventBus.js";
// 触发的太快，函数防抖
import { debounce } from "@/utils";

// 存放绑定指令的所有的图片，存放的是对象{dom:,src:,}
let imgs = [];

// 使用事件总线  
// 滚动条滚动，看哪些图片需要加载,传递的参数过来
eventBus.$on("mainScroll", debounce(handleScroll, 50));
// 每隔2s 输出一下图片
/* setInterval(() => {
    console.log(imgs);
}, 2000)
 */
// 导出配置对象 
export default {
    // 钩子函数   
    /* 
    只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
    el 是被绑定元素对应的真实DOM
    bindings 是一个对象，描述了指令中提供的信息
    bindings.value 是 指令后面js 表达式的内容，即图片的地址
    handled:false,是否已经处理过，默认一开始是false
    翻页后也会不断的加进来，翻页后之前的图片实际不用保存
    */
    // 被绑定元素插入父节点时调用。元素有高度，使用bing 没有办法获取元素的高度
    inserted(el, bindings) {
        const img = {
            dom: el,
            src: bindings.value,
            handled: false,
        }
        imgs.push(img);
        // 在触发滚动条前，还没有滑动，如果可以处理，则进行处理
        setImage(img);
    },
    // 翻页 切换到其他组件后，数组中的内容清空
    unbind(el) {
        // unbind：只调用一次，指令与元素解绑时调用,元素结点消失时执行
        // imgs  有解绑和未解绑的，将未解绑的留下
        imgs = imgs.filter((img) => img.dom != el)
    },
}
