<!-- 头像组件，文件名大驼峰命名方式 -->
<!-- 这个模板不需要写入到 导出里面了，因为没有模板编译系统了  -->
<template>
  <!-- 绑定 style 的时候是绑定的一个对象 -->
  <div class="avatar-container">
    <img
      class="avatar-container"
      :src="url"
      :style="{
        width: size + 'px',
        height: size + 'px',
      }"
    />
  </div>
</template>

<script>
export default {
  //  属性也可以使用对象
  // props:{
  //     // 属性名称 属性类型
  //     url:String,
  // }

  // props: ["url"],

  // 设置属性必传
  props: {
    url: {
      type: String,
      required: true, // 属性必须传递
    },
    // // 宽度
    // width: {
    //   type: Number,
    // },
    // // 高度
    // height: {
    //   type: Number,
    // },

    // 宽高一致，可以传递为一个属性
    size: {
      type: Number,
      default: 150,
    },
  },
};
</script>

<!-- 为了避免属性冲突，和其他组件的，加上一句话 scoped,
成为带有作用域的样式
 -->
<style scoped lang = "less">
.avatar-container {
  // width:100%;
  border-radius: 50%;
  /* 避免图片拉伸 */
  object-fit: cover;
  display: block;
  margin: 0 auto;
}
</style>
