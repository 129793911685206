import Vue from "vue";
/**
   获取某个组件渲染的Dom根元素
   参数： 组件对象，组件属性
 */
export default function (comp, props) {
    const vm = new Vue({
        render: h => h(comp, { props })
    })
    vm.$mount();
    // 获取dom 元素
    return vm.$el;
}