<template>
  <div class="site-aside-container">
    <!-- 想给组件设置样式的时候，可以直接在外面写div -->
    <!-- <div class="avater"> -->
    <!-- 先判断是否有值 -->
    <!--一开始状态里面的data 为null -->
    <template v-if="data">
      <Avatar :url="data.avatar" :size="size" />
      <h1 class="title">{{ data.siteTitle }}</h1>
    </template>
    <Menu />
    <!-- <Contact v-if="data" /> -->
    <template v-if="data">
      <p class="footer">{{ data.icp }}</p>
    </template>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar";
// import Contact from "@/components/Contact";
// import Menu from "@/components/Menu";
// 当前目录下的，上面那样写也可以找到
import Contact from "./Contact";
import Menu from "./Menu";
import { mapState } from "vuex";
export default {
  components: {
    Contact,
    Avatar,
    Menu,
  },
  computed: mapState("setting", ["data"]),
  data() {
    return {
      // url: "https://www.zznlife.com/i/2022/09/18/6326050c161d6.jpg",
      size: 120,
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/var.less";
.site-aside-container {
  box-sizing: border-box;
  width: 100%;
  // 高度实际值如果不够会出现滚动条
  height: 100%;
  background-color: @dark;
  padding: 20px 0 20px 10px;
  //   横向超出
  overflow-x: hidden;
  //   纵向超出滚动条
  overflow-y: auto;
  .footer {
    font-size: 12px;
    text-align: center;
  }
  .title {
    font-size: 1.2em;
    text-align: center;
    color: @white;
  }
}
</style>
