import Vue from "vue";
// 使用路由
import VueRouter from "vue-router";

// 外部引入 vueRouter 会污染全局变量
// 如果没有污染，则说明没有使用外部引入，没有自动注册
// 则可以使用Vue.use(VueRouter)
if(!window.VueRouter){
    // 没有使用传统的方式引入VueRouter
    Vue.use(VueRouter);
  }
// Vue.use(VueRouter);
// 开发环境给提示的，可删除 vue3 已经删除掉了
// Vue.config.productionTip = false
import routes from "./routes";
import {titleController} from "@/utils"
// 得到路由实例
const router = new VueRouter({
    routes,
    // mode: "history",
    // 打包环境更改模式
    mode:"hash",
});

// afterEach 沒有next
router.afterEach((to,from) => {
    if(to.meta.title){
       titleController.setRouterTitle(to.meta.title)
    }
})
// 导出
export default router;