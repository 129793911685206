import Vue from 'vue'
import setting from './setting'
import banner from './banner'
import about from './about'
import project from "./project"
// import Vuex from 'vuex'
// Vue.use(Vuex)
// vuex 优化
import {Store,install} from "vuex"

if(!window.Vuex){
    // 没有使用传统的方式引入Vuex
    install(Vue)
  }
// install(Vue)

// const store = new Vuex.Store
const store = new Store({
    modules:{
        setting,
        banner,
        about,
        project,
    },
    strict:true,
})
export default store