// 网站标题控制
var routerTitle = '', siteTitle = '';
function setTitle() {
    // 都没有，显示loading...
    if (!routerTitle && !siteTitle) {
        document.title = "loading..."
    } else if (!routerTitle && siteTitle) {
        // 只有网站标题
        document.title = siteTitle
    }else if(routerTitle && !siteTitle){
        // 只有路由标题
        document.title = routerTitle
    }else{
        // 路由和网站标题都有
        document.title = `${routerTitle}-${siteTitle}`
    }
}


export default {
    // 设置路由标题
    setRouterTitle(title) {
        routerTitle = title
        setTitle()
    },
    // 设置网站标题
    setSiteTitle(title) {
        siteTitle = title
        setTitle()
    }
}
