<template>
  <nav class="menu-container">
    <!-- key 值可以用 item.link  自己用的index-->
    <li v-for="item in items" :key="item.link">
      <!-- 目前被选中的样式
      vue-cli 搭建开发服务器，访问任何一个地址都是同一个页面
      靠vue 来渲染
      -->
      <!-- 改动了规则 router-link-active 改成了精确匹配 -->
      <!-- active-class:   router-link-active 的名字改为 selected -->
      <!-- exact-active-class="" 不要exact-router-link-active 了 -->
      <RouterLink
        :exact="item.exact"
        :to="{ name: item.name }"
        active-class="selected"
        exact-active-class=""
      >
        <div class="icon">
          <Icon :type="item.type" />
        </div>
        <span>{{ item.text }}</span>
      </RouterLink>
    </li>
  </nav>
</template>

<script>
import Icon from "@/components/Icon";
export default {
  components: {
    Icon,
  },
  data() {
    return {
      // 老师用的 link title icon
      // exact: true 效果是router-link-active 改为 精确匹配
      // exact:false 效果是router-link-active 仍是模糊匹配
      items: [
        { type: "home", name: "Home", text: "首页", exact: true },
        // 只要以当前开头，则是选中状态
        // 用name 自动去index.js 中找路径
        { type: "blog", name: "Blog", text: "文章", exact: false },
        { type: "about", name: "About", text: "关于我", exact: true },
        { type: "code", name: "Project", text: "项目&效果", exact: true },
        { type: "chat", name: "Message", text: "留言板", exact: true },
      ],
    };
  },
  // //   新知识
  // methods: {
  //   isSeleted(item) {
  //     // 当前的路径是否是path window.location.pathname 控制台下打印地址
  //     let path = item.link.toLowerCase(); // 菜单链接地址
  //     let currentPath = location.pathname.toLowerCase(); // 当前浏览器的访问路径
  //     // 是否有starWith 属性
  //     //   文章下面有很多地址 /blog..... 以这个path 开头就可以
  //     if (item.startWith) {
  //       return currentPath.startsWith(path); // 是否以path 开头
  //     } else {
  //       return currentPath === path;
  //     }
  //   },
  // },
};
</script>

<style lang="less" scoped>
@import "~@/styles/var.less";
.menu-container {
  width: 100%;
  margin: 24px 0;
  @itemHeight: 30px;
  li {
    list-style: none;
    // height: @itemHeight;
    // line-height: @itemHeight;
    margin: 14px 0;
    color: @gray;
  }
  a {
    height: 24px;
    display: block;
    padding: 0 50px;
    display: flex;
    // 纵向对齐
    align-items: center;
    // 选中之后的颜色
    // 在routerLink中，选中之后会自己填上这个样式router-link-exact-active
    &.selected {
      // background-color: #2d2d2d;
      // 对颜色加深20%
      background-color: darken(@words, 3%);
    }
  }
  a:hover {
    color: #fff;
  }
  .icon {
    display: inline-block;
    font-size: 16px;
    width: 24px;
  }
}
</style>
